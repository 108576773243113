import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/default-template.tsx";
import Features from "@/components/Features";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>

    <h2>{`👋 Introduction`}</h2>
    <p>{`A Gatsby starter template to publish `}<a parentName="p" {...{
        "href": "https://obsidian.md/"
      }}>{`Obsidian`}</a>{` vault for free, created by `}<a parentName="p" {...{
        "href": "https:/juxtdesign.cc/?utm_source=obsidian-garden"
      }}>{`@juxtdesigncc`}</a></p>
    <h2>{`🚀 Quick Start`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/dashboard/deploynow?url=https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden"
      }}><img parentName="a" {...{
          "src": "https://camo.githubusercontent.com/fda7a8fe64d0351d9eb4aff4446b92368c7d5d9a55889fd16041eb393c5a697d/68747470733a2f2f7777772e6761747362796a732e636f6d2f6465706c6f796e6f772e737667",
          "alt": "Deploy to Gatsby Cloud Now"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://app.netlify.com/start/deploy?repository=https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden"
      }}><img parentName="a" {...{
          "src": "https://www.netlify.com/img/deploy/button.svg",
          "alt": "Deploy to Netlify Now"
        }}></img></a></p>
    <h2>{`💎 Key Features`}</h2>
    <p>{`Obsidian currently offers Publish services ($20/month) with advance features. This starter is a free alternative for Obsidian users who want to publish their digital garden as a website. Obsidian can also serve as an on-device CMS to manage your content. (Cross-platform synchronization is also possible via Obsidian Sync / `}<a parentName="p" {...{
        "href": "https://desktopofsamuel.com/how-to-sync-obsidian-vault-for-free-using-git/?utm_source=github&utm_medium=readme&utm_id=starter-obsidian"
      }}>{`Git`}</a>{`)`}</p>
    <Features mdxType="Features" />
    {/* - Publish your [Obsidian](https://obsidian.md/) knowledge base as a website for free using [Gatsby](https://gatsbyjs.com/)
     - Support YAML (e.g. tags, publish) that are also supported by Obsidian */}
    <h2>{`🚀 Getting Started`}</h2>
    <h3>{`1. Create a Gatsby site.`}</h3>
    <p>{`Clone this template and navigate into the folder`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-node"
      }}>{`# Create a gatsby site with npx
npx gatsby new project-name https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden
cd project-name

# or
git clone https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden project-name
cd project-name
`}</code></pre>
    <h3>{`2. Install dependencies.`}</h3>
    <p>{`Install dependencies using yarn or npm`}</p>
    <pre><code parentName="pre" {...{}}>{`# Using yarn
yarn install

# Using npm
npm install
`}</code></pre>
    <h3>{`3. Run development server`}</h3>
    <p>{`Let's run the dev server to know it's working before updating any content or code.`}</p>
    <pre><code parentName="pre" {...{}}>{`gatsby develop
`}</code></pre>
    <p>{`Your site is now running at http://localhost:8000!`}</p>
    <h3>{`4. Open the code and start customizing`}</h3>
    <p>{`Start customizing:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/content/vault`}</inlineCode>{` stored all demo content. You can also use Obsidian to open the folder as a vault.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/config.js`}</inlineCode>{` to customise the site metadata, top and footer navigation`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/gatsby`}</inlineCode>{` to customise all page generation in `}<inlineCode parentName="li">{`gatsby-node.js`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`code .
`}</code></pre>
    <h2>{`💻 Setup`}</h2>
    <p><strong parentName="p">{`Read before you use your own Obsidian vault or create a new one`}</strong></p>
    <p>{`Whether you wish to use your existing Obsidian vault or start a new vault, the following Obsidian preferences has to be set up correctly. Starting a new vault is highly recommended as these options are not on by default. All existing notes using the `}<inlineCode parentName="p">{`[[Wikilinks]]`}</inlineCode>{` will need to update manually. Here're a list of preferences:`}</p>
    <ul>
      <li parentName="ul">{`Settings - Files & Links: Use [`}{`[Wikilinks]`}{`] option should be OFF. It will change the link format from `}<inlineCode parentName="li">{`[[Wikilinks]]`}</inlineCode>{` to `}<inlineCode parentName="li">{`[MarkdownLink]`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Settings - Default location for new attachments: Should be set as 'In a folder specificed below' and create a folder named `}<inlineCode parentName="li">{`media`}</inlineCode>{`, remember to update the config in `}<inlineCode parentName="li">{`gatsby-source-filesystem`}</inlineCode>{` in `}<inlineCode parentName="li">{`gatsby-config.js`}</inlineCode>{` if the media folder has changed.`}</li>
      <li parentName="ul">{`By default, all notes will be published , but add frontmatter `}<inlineCode parentName="li">{`publish: false`}</inlineCode>{` to hide a note.`}</li>
    </ul>
    <p>{`Current example vault in `}<inlineCode parentName="p">{`/content/vault`}</inlineCode>{` has already implemented all the aforementioned preference settings`}</p>
    <h3>{`Why `}<inlineCode parentName="h3">{`gatsby-remark-relative-images`}</inlineCode>{` need to be not the latest version?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/63698552/gatsby-node-js-threw-an-error-while-running-the-oncreatenode-lifecycle-fmimag"
      }}>{`https://stackoverflow.com/questions/63698552/gatsby-node-js-threw-an-error-while-running-the-oncreatenode-lifecycle-fmimag`}</a></p>
    <h2>{`💪🏻 Support`}</h2>
    <p>{`Thank you for trying this out! I'm still constantly shipping new updates to this project. Stay tuned for more news!`}</p>
    <p>{`You can support this project by:`}</p>
    <ul>
      <li parentName="ul">{`Raise issues on `}<a parentName="li" {...{
          "href": "https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden/issues"
        }}>{`Github`}</a>{` if you catch any bugs`}</li>
      <li parentName="ul">{`Submit a `}<a parentName="li" {...{
          "href": "https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden/pulls"
        }}>{`PR`}</a>{` if you discovered a solution`}</li>
      <li parentName="ul">{`Share this project on Twitter and tag `}<a parentName="li" {...{
          "href": "https://twitter.com/juxtdesigncc"
        }}>{`me`}</a>{`!`}</li>
      <li parentName="ul">{`Give me a star on `}<a parentName="li" {...{
          "href": "https://github.com/juxtdesigncc/gatsby-starter-obsidian-garden/"
        }}>{`Github`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      